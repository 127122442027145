import { variables, mq } from 'cssInJs';
import { settings as defaultSettings } from 'site/tileList/config';

import { rgba } from 'polished';

export function getTileStyle(settings = defaultSettings) {
  const numOfTilesPerBreakpoints = settings.numOfTilesPerBreakpoints
  // const marginLR = settings.marginLR
  // const marginTB = settings.marginTB

  return {
    tile: mq({
      // paddingLeft: marginLR.map(x => x === null ? null : x / 2),
      // paddingRight: marginLR.map(x => x === null ? null : x / 2),
      // paddingTop: marginTB.map(x => x === null ? null : x / 2),
      // paddingBottom: marginTB.map(x => x === null ? null : x / 2),
      maxWidth: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexBasis: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      position: "relative",
      overflow: "hidden",
      margin: 0,
      width: "100%",
      height: "100%",
      color: 'inherit',
      ':hover': {
        textDecoration: 'none',
        color: 'inherit',
        '.tileBg': {
          transition: 'all .2s ease-in-out',
        },
        '.tileContent': {
          backgroundColor: rgba("black", 0.3),
          opacity: 1,
          transform: 'translateY(0%)',
        },
      },
    }),
    tileSlider: mq({
      maxWidth: '100% !important'
    }),
    tileBg: mq({
      backgroundColor: 'white',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingBottom: '56.25%',
      height: 0
    }),
    tileBgTitle: mq({
      textTransform: "uppercase",
      textAlign: "center",
      backgroundColor: rgba("black", 0.3),
      paddingBlock: "1rem",
      marginTop: "9rem"
    }),
    tileContent: mq({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1,
      lineHeight: 1.3,
      //boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
      backgroundColor: rgba("black", 0.5),
      marginTop: 0,
      padding: [20],
      transition: 'all .2s ease-in-out',
      position: "absolute",
      top: 0,
      bottom: 0,
      opacity: 0,
      width: '100%',
      transform: "translateY(100%)",
      height: "100%"
    }),
    title: mq({
      //fontFamily: variables.familyHeader,
      fontSize: '1.3rem',
      textTransform: 'uppercase',
      lineHeight: 1.3,
      fontWeight: 900,
      textAlign: 'left',
    }),
    desc: mq({
      marginTop: 5,
      fontSize: '0.9rem',
      display: '-webkit-box',
      WebkitLineClamp: '9',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }),
  }
}

export function getTileListOverridingStyle() {
  return {
    tiles: mq({
      alignItems: 'flex-start',
    })
  }
}
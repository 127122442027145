/** @jsx jsx */
import { jsx } from '@emotion/core'
import utils from 'utils';
import { SiteLink } from 'components';
import { getTileStyle } from './pageTileStyle';

export function PageTile({ page, useSlider, disableLink }) {
  const tileLink = page.pageUrl;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const title = page.pageTitle;
  const desc = page.tileDesc || page.description;
  const s = getTileStyle();
  return (
    <SiteLink to={tileLink} css={[s.tile, useSlider && s.tileSlider]} >
        <div className="tileBg" css={s.tileBg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}>
          <div css={s.tileBgTitle}>
            {title}
          </div>
        </div>
        <div className="tileContent" css={s.tileContent}>
          <div css={s.title}>{title}</div>
          <div css={s.desc}>{desc}</div>
      </div>
      
    </SiteLink>
  )
}

// //import { css, keyframes  } from '@emotion/core'
// //import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
    photoGallery : css({
        maxWidth : '960px',
        margin: 'auto'
    }),
    // thumbnails : css({
    //     display:'flex',
    //     flexWrap:'wrap',
    //     marginLeft:'-5px',
    //     marginRight:'-5px'
    // }),
    // thumbnail : css(mq({
    //     //flexBasis: ['50%','25%','33.33%'],
    //     // flexBasis: ['20%','20%','16.66%'],
    //     flexBasis: "20%",
    //     //padding: ['5px']
    //     padding:'5px',
    //     cursor: 'pointer'
    // })),
    // // thumbnail : css(mq({
    // //     //flexBasis: ['50%','25%','33.33%'],
    // //     // flexBasis: ['20%','20%','16.66%'],
    // //     flexBasis: ['20%', '20%', '20%', '20%', '20%'],
    // //     //padding: ['5px']
    // //     padding:'5px',
    // //     cursor: 'pointer'
    // // })),
    // bg : {
    //     paddingBottom: '100%',
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     backgroundRepeat: 'no-repeat',
    // }
}